
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
  background-color: rgba(243,250,249,255);

}

:root {
  --main-bg-color: rgba(125,208,194,255);
  --bg-color-first-shade : rgba(125,208,194,255);
  --bg-color-second-shade : rgba(243,250,249,255);
  --color-primary : #0f9a83;
  --color-white : #ffffff;
  --color-blue : #254086

}

