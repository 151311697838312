#footer {
    padding: 20px 50px;
    padding-right: 90px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

.footer-logo {
  margin-left: 1.5rem;
}

.footlogo {
  height: 55px;
  width: auto;
}

.footer-contents {
    color: var(--color-white);
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-link-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    list-style: none;
    font-weight: 500;
  }

  .footer-link-items:hover {
    text-decoration: underline;
    text-decoration-color: var(--color-white);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    cursor: pointer;
    animation: footerUnderlineAppear 0.5s ease forwards;
  }
  
  @keyframes footerUnderlineAppear {
    from {
      text-decoration: underline transparent;
    }
    to {
      text-decoration-color: var(--color-white);
    }
  }

  .footer-para {
    color: var(--color-white);
    text-align: center;
  }
  
  .footer-content-title {
    margin-left: 20px;
  }

  .foot-titles{
    gap: 0.1rem;
    font-weight: bold;
  }

  .foot-second-title{
    margin-bottom: 30px;
  }
  
  
  .footer-link-items {
    color: var(--color-white);
  }

  .footer-social-link-items {
    color: var(--color-white);
    opacity: 1;
    transition: opacity 1s ease 0s;
  }

  .footer-social-link-items:hover {
    opacity: 0.6;
  }

  @media screen and (max-width: 1200px){
    .footer-container {
      display: flex;
      flex-direction: column;
    }
    .footer-para {
      color: var(--color-white);
      text-align: left;
    }

    .footer-link-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

  }

  @media screen and (min-width: 500px) and (max-width: 1200px) {
    .footer-para {
      text-align: center;
    }

}