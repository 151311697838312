#companies {
    background-color: var(--bg-color-second-shade);
    padding: 20px 5%;
}

#companies h2 {
    color: var(--color-blue);
    font-size: 2.5rem;
}

.companies-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.companies-card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 8px 3px rgba(0, 0, 0, 0.1);
    height: auto;
    min-height: 25rem;
    width: 20rem;
    align-items: center;
    margin: 0 auto 40px;
    align-items: start;
}

.comanies-card-icon {
    background-color: var(--color-white);
    height: 40%;
    width: 100%;
    flex: 40%;
}

.companies-card-content {
    background-color: var(--color-primary);
    color: var(--color-white);
    width: 100%;
    flex: 60%;
}

.icon {
    height: 9rem;
    width:20rem;
}

.card-7 .icon, .card-8 .icon {
    height: 6.5rem;
    padding-top: 10px
}

.companies-card-content p {
    padding: 1rem 1rem;
    font-weight: 100;
}

a{
    text-decoration: none;
}

.companies-card > .companies-card-content {
    transition: background-color 0.3s ease;
  }

.card-1 > .companies-card-content {
    background-color: #10497e;
}
.card-2 > .companies-card-content {
    background-color: #2ab274;
}
.card-3 > .companies-card-content {
    background-color: #783690;
}
.card-4 > .companies-card-content {
    background-color: #d88ba7;
}
.card-5 > .companies-card-content {
    background-color: #af1f69;
}
.card-6 > .companies-card-content {
    background-color: #007a79;
}
.card-7 > .companies-card-content {
    background-color: #18a38e;
}
.card-8 > .companies-card-content {
    background-color: #303791;
}
.card-9 > .companies-card-content {
    background-color: #c2a164;
}

@media screen and (max-width: 1200px){

    .companies-card-container {
        display: flex;
        flex-direction: column;
    }

    #companies {
        padding-right: auto;
    }

  }

@media screen and (min-width: 800px) and (max-width: 1200px) {
    .companies-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

